import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.includes.js";
import useNumberFormatters from "~/functions/useNumberFormatters";
import useBaseUtils from "~/functions/useBaseUtils";
import { convertToUsdObject, scaleEstimateDataObject } from "~/utils/values/scale";
export default defineComponent({
  props: {
    currDateObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    prevDateObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    denomDataObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    numerDataObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    lineitem: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    // formatclass: {
    //   type: [String, Array],
    //   default: "black--text", // this isn't right, dependent upon darkmode
    // },
    decimals: {
      type: Number,
      "default": 2
    },
    tousd: {
      type: Boolean,
      "default": false
    },
    showturns: {
      type: Boolean,
      "default": true
    },
    dateidx: {
      type: Number,
      "default": 0
    },
    datelength: {
      type: Number,
      "default": 0
    },
    reversedates: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(props) {
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      dev = _useBaseUtils.dev,
      tenYearCutoff = _useBaseUtils.tenYearCutoff;
    var _useNumberFormatters = useNumberFormatters(),
      formatInt = _useNumberFormatters.formatInt;
    var createNumberFormatter = function createNumberFormatter(decimals) {
      var lang = navigator.language || "default";
      return new Intl.NumberFormat(lang, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
    };
    var formatNumber = computed(function () {
      return createNumberFormatter(props.decimals);
    });
    var createPercentFormatter = function createPercentFormatter(pctDecimals) {
      var lang = navigator.language || "default";
      return new Intl.NumberFormat(lang, {
        style: "percent",
        minimumFractionDigits: pctDecimals,
        maximumFractionDigits: pctDecimals
      });
    };
    var formatPercent = createPercentFormatter(1);
    var scaleDenominator = function scaleDenominator(dataObj) {
      var toUSD = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var fallbackValue = 0;
      if (toUSD) {
        var objInUSD = convertToUsdObject(dataObj, 0);
        return scaleEstimateDataObject({
          dataObj: objInUSD,
          fallbackValue: fallbackValue
        });
      }
      return scaleEstimateDataObject({
        dataObj: dataObj,
        fallbackValue: fallbackValue
      });
    };
    var rawEstimateMetric = computed(function () {
      var _props$lineitem = props.lineitem,
        formula = _props$lineitem.formula,
        name = _props$lineitem.name;
      return formula === "val" && name.includes("NTM");
    });
    var rawFinancialMetric = computed(function () {
      var _props$lineitem2 = props.lineitem,
        formula = _props$lineitem2.formula,
        name = _props$lineitem2.name;
      return formula === "val" && name.includes("LTM");
    });
    var greaterThanEightQuarters = computed(function () {
      if (props.reversedates) {
        return props.dateidx > 8;
      } else {
        return props.dateidx < props.datelength - 8;
      }
    });
    var outsideTenYears = computed(function () {
      return props.currDateObj.calendaryear < tenYearCutoff();
    });
    return {
      refCode: refCode,
      dev: dev,
      formatInt: formatInt,
      formatNumber: formatNumber,
      formatPercent: formatPercent,
      scaleDenominator: scaleDenominator,
      rawEstimateMetric: rawEstimateMetric,
      rawFinancialMetric: rawFinancialMetric,
      greaterThanEightQuarters: greaterThanEightQuarters,
      outsideTenYears: outsideTenYears
    };
  },
  computed: {
    cellValue: function cellValue() {
      var lineitem = this.lineitem;
      var currDateObj = this.currDateObj;
      var prevDateObj = this.prevDateObj;
      var denomDataObj = this.denomDataObj;
      var numerDataObj = this.numerDataObj;
      var toUSD = this.tousd;
      var showTurns = this.showturns;
      if (lineitem.formula === "div") {
        return this.formatMultipleData(currDateObj, prevDateObj, denomDataObj, numerDataObj, lineitem.type, showTurns);
      } else if (lineitem.type === "float") {
        var dataObj = denomDataObj[currDateObj.value] ? denomDataObj[currDateObj.value] : denomDataObj[prevDateObj.value];
        if (dataObj) {
          var scaledRes = this.scaleDenominator(dataObj, toUSD);
          return "".concat(scaledRes > 0 ? "".concat(this.formatNumber.format(scaledRes)) : "(".concat(this.formatNumber.format(Math.abs(scaledRes)), ")"));
        } else {
          return "";
        }
      } else if (lineitem.type === "pct") {
        var _dataObj = denomDataObj[currDateObj.value];
        var result = _dataObj ? _dataObj.v / 100 : 0;
        return this.printResult(result, this.formatPercent);
      } else {
        // I guess these are the counts, are not related to currency?
        var _dataObj2 = denomDataObj[currDateObj.value];
        var _result = _dataObj2 ? _dataObj2.v : 0;
        return this.printResult(_result, this.formatInt);
      }
    },
    unauth: function unauth() {
      var lineitem = this.lineitem;
      var currDateObj = this.currDateObj;
      var prevDateObj = this.prevDateObj;
      var denomDataObj = this.denomDataObj;
      var numerDataObj = this.numerDataObj;
      if (lineitem.formula === "div") {
        // figure out if the division uses any unauthorized values
        // guess for now it just depends on denomDataObj
        return this.divisionUnauthorized(currDateObj, prevDateObj, denomDataObj, numerDataObj);
      } else if (lineitem.type === "float") {
        var dataObj = denomDataObj[currDateObj.value] ? denomDataObj[currDateObj.value] : denomDataObj[prevDateObj.value];
        return Boolean(dataObj === null || dataObj === void 0 ? void 0 : dataObj.unauth);
      } else {
        var _dataObj3 = denomDataObj[currDateObj.value];
        return Boolean(_dataObj3 === null || _dataObj3 === void 0 ? void 0 : _dataObj3.unauth);
      }
    },
    formatclasses: function formatclasses() {
      var denomDataObj = this.denomDataObj;
      var currDateObj = this.currDateObj;
      var prevDateObj = this.prevDateObj;
      return this.generateFormatClasses(denomDataObj[currDateObj.value], denomDataObj[prevDateObj.value]);
    }
  },
  methods: {
    generateFormatClasses: function generateFormatClasses() {
      var currDataObj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var prevDataObj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var currKeys = Object.keys(currDataObj).length > 0;
      var prevKeys = Object.keys(prevDataObj).length > 0;
      var classes = [];
      var positiveColor = this.$vuetify.theme.dark ? "accent--text" : "black--text";
      if (currKeys) {
        var dataObj = currDataObj;
        dataObj.v >= 0 ? classes.push(positiveColor) : classes.push("red--text");
      } else if (prevKeys) {
        var _dataObj4 = prevDataObj;
        _dataObj4.v >= 0 ? classes.push(positiveColor) : classes.push("red--text");
      } else {
        classes.push(positiveColor);
      }
      return classes;
    },
    divisionUnauthorized: function divisionUnauthorized(currDate, prevDate, denomObj, numerObj) {
      var denomDataObj = denomObj[currDate.value] ? denomObj[currDate.value] : denomObj[prevDate.value];
      if (denomDataObj !== null && denomDataObj !== void 0 && denomDataObj.unauth) {
        return true;
      }
      var numerDataObj = numerObj[currDate.value] ? numerObj[currDate.value] : numerObj[prevDate.value];
      return numerDataObj === null || numerDataObj === void 0 ? void 0 : numerDataObj.unauth;
    },
    formatMultipleData: function formatMultipleData(currentDate,
    // dateKey,
    // dataitemid,
    prevDate, denomObj, numerObj, type) {
      var showTurns = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : true; // check if the dataitemid that will be the
      // numerator exists, if it doesn't exist exit the function
      // we already know from this.checkDenominator that this
      // dataitemid exists on the dataobject
      // prettier-ignore
      try {
        var data = denomObj[currentDate.value]; // const date = currentDate // used when numerator was always on date key
        if (!data) {
          if (prevDate) {
            data = denomObj[prevDate.value];
            if (!data) {
              return;
            }
          } else {
            return;
          }
        }
        if (!data.v) {
          return;
        } // const numerator = date[dateKey] // used when numerator was always on date key
        var numeratorObj = numerObj[currentDate.value];
        if (!numeratorObj) {
          return;
        }
        var numerator = numeratorObj.v; // I have to figure out what I'm looking for in numerator/denominator and then convert
        var numeratorFx = numeratorObj.pc;
        var numeratorIsoCode = numeratorObj.iso;
        var denominatorIsoCode = data.iso;
        var result = numerator / this.scaleDenominator(data);
        if (numeratorIsoCode !== denominatorIsoCode) {
          var numeratorUSD = numerator / numeratorFx;
          var denominatorUSD = this.scaleDenominator(data, true);
          result = numeratorUSD / denominatorUSD;
        } // both exist, do the math
        if (type === "pct") {
          return "".concat(result > 0 ? "".concat(this.formatPercent.format(result)) : "(".concat(this.formatPercent.format(Math.abs(result)), ")"));
        } else {
          return "".concat(result > 0 ? "".concat(this.formatNumber.format(result)).concat(showTurns ? "x" : "") : "(".concat(this.formatNumber.format(Math.abs(result))).concat(showTurns ? "x" : "", ")"));
        }
      } catch (e) {
        if (this.dev) {
          console.error("formatMultiple Data Error: ", e);
        }
      }
    },
    printResult: function printResult(result, formatter) {
      return result ? "".concat(result > 0 ? "".concat(formatter.format(result)) : "(".concat(formatter.format(Math.abs(result)), ")")) : "";
    }
  }
});